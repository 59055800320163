import type { Address } from "viem"
import { mainnet } from "viem/chains"
import { createSiweMessage } from "viem/siwe"

export const getAddWalletSiweMessage = ({
  address,
  nonce,
  domain,
  uri,
  email,
}: {
  email: string
  address: Address
  nonce: string
  domain: string
  uri: string
}) => {
  return getSiweMessage({
    address,
    nonce,
    domain,
    uri,
    statement: `I confirm the linking of my wallet to IYK account with email address ${email}`,
  })
}

const getSiweMessage = ({
  address,
  nonce,
  domain,
  uri,
  statement,
}: {
  address: Address
  nonce: string
  domain: string
  uri: string
  statement: string
}) => {
  return createSiweMessage({
    address,
    domain,
    nonce,
    uri,
    chainId: mainnet.id,
    version: "1",
    statement,
  })
}
