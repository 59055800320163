/**
 * A pure utility function that shortens hex addresses
 *
 * @param hex a hex address to shorten
 * @param options can specify the frontLength or backLength values
 *
 * @returns
 */
export function shortenHex(
  hex: string,
  options?: { frontLength?: number; backLength?: number },
) {
  const { frontLength, backLength } = Object.assign(
    { frontLength: 4, backLength: 4 },
    options,
  )

  const totalFrontLength = frontLength + 2
  if (!hex) return ""

  if (hex.length < totalFrontLength + backLength) {
    return hex
  }

  return `${hex.substring(0, totalFrontLength)}…${hex.substring(
    hex.length - backLength,
  )}`
}
