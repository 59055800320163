import type { MotionProps } from "framer-motion"

import * as React from "react"

import { motion } from "framer-motion"
import { cn } from "../../lib/utils/classname.ts"

// https://github.com/framer/motion/discussions/1884

interface AnimateHeightChange extends MotionProps {
  children: React.ReactNode
  className?: string
}

export const AnimateHeightChange = ({
  children,
  className,
  ...props
}: AnimateHeightChange) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = React.useState<number | "auto">("auto")

  React.useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        // We only have one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height
        setHeight(observedHeight)
      })

      resizeObserver.observe(containerRef.current)

      return () => {
        // Cleanup the observer when the component is unmounted
        resizeObserver.disconnect()
      }
    }
  }, [])

  // In CSS, it's not possible to animate to or from a value of "auto".
  // Let's tell framer-motion to not animate at all in that case.
  const animate = height === "auto" ? false : { height }

  return (
    <motion.div
      className="overflow-hidden"
      animate={animate}
      transition={{ duration: 0.5, ease: [0.32, 0.72, 0, 1] }}
      {...props}
    >
      <div ref={containerRef} className={cn("flex flex-col gap-2", className)}>
        {children}
      </div>
    </motion.div>
  )
}
